import * as React from "react";
import styled from "styled-components";
import useCheckout from "../../hooks/useCheckout";
import useLink from "../../hooks/useLink";
import { COLORS } from "../../styles/helpers";
import CartDrawer from "../cart/CartDrawer";
import Drawer from "./Drawer";
import Logo from "../promotion/Logo";
import Button from "../common/Button";
import { globalHistory } from "@reach/router";

export default function Menu({ isIndex }: { isIndex: boolean }) {
  const { navigate } = useLink();
  const [expand, setExpand] = React.useState(false);
  const [scroll, setScroll] = React.useState<number>(-1);
  const [SSR, setSSR] = React.useState(true);

  React.useEffect(() => {
    setSSR(false);

    if (isIndex) {
      const fn = () => setScroll(window.pageYOffset);

      window.addEventListener("scroll", fn, { passive: true });

      return () => window.removeEventListener("scroll", fn);
    }
  }, []);

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setExpand(false);
      }
    });
  }, []);

  return (
    <Wrapper transparent={isIndex && scroll < 48}>
      <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
        <Hamburger onClick={() => setExpand((e) => !e)}>
          <div />
          <div />
          <div />
        </Hamburger>
        <Logo width={28} fill="#FFF" />
      </div>

      {expand && (
        <Drawer onClose={() => setExpand(false)} left>
          <MenuDrawer>
            <Logo text style={{ margin: "auto", marginBottom: 30, width: 124 }} />
            <a onClick={() => navigate("/")}>Hem</a>
            <a onClick={() => navigate("/products")} style={{ marginBottom: 40 }}>
              Alla produkter
            </a>
            <a onClick={() => navigate("/contact")}>Kontakta oss</a>
            <a onClick={() => navigate("/shipping")}>Leverans &amp; Retur</a>
            <a onClick={() => navigate("/sizeguide")}>Storleksguide</a>
            <a onClick={() => navigate("/toc")}>Terms & Conditions</a>
            <a onClick={() => navigate("/privacy")}>Privacy Policy</a>
          </MenuDrawer>
        </Drawer>
      )}

      {!SSR && <CartMenu />}
    </Wrapper>
  );
}

const CartMenu = () => {
  const [expand, setExpand] = React.useState(false);
  const { cart } = useCheckout();

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setExpand(false);
      }
    });
  }, []);

  return (
    <>
      <Button name="open-cart" text onClick={() => setExpand((e) => !e)} style={{ position: "relative" }}>
        {!!cart?.[0] && <Dot>{cart?.length}</Dot>}
        <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
          <path
            fill="#fff"
            d="M19 6H17C17 3.2 14.8 1 12 1S7 3.2 7 6H5C3.9 6 3 6.9 3 8V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V8C21 6.9 20.1 6 19 6M12 3C13.7 3 15 4.3 15 6H9C9 4.3 10.3 3 12 3M19 20H5V8H19V20M12 12C10.3 12 9 10.7 9 9H7C7 11.8 9.2 14 12 14S17 11.8 17 9H15C15 10.7 13.7 12 12 12Z"
          />
        </svg>
      </Button>

      {expand && <CartDrawer onClose={() => setExpand(false)} />}
    </>
  );
};

const Wrapper = styled.div<{ transparent: boolean }>`
  background: ${({ transparent }) => (transparent ? "transparent" : COLORS.dark)};
  transition: background-color 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const Hamburger = styled.div`
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  > div {
    width: 100%;
    height: 2px;
    background: #fff;
  }
`;

const Dot = styled.div`
  background: ${COLORS.beige};
  position: absolute;
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  top: -4px;
  left: -4px;
  font-weight: bold;
  font-size: 11px;
`;

const MenuDrawer = styled.div`
  a {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
`;
