import { navigate } from "gatsby";
import useCheckout from "./useCheckout";

export default function useLink() {
  const { store } = useCheckout();

  const isAbsolutePath = (path: string) => path?.startsWith(`/`);
  const localizePath = (path: string) => (isAbsolutePath(path) ? `/${store.countryCode.toLowerCase()}${path}` : path);

  return {
    navigate: (to: string, options?: any) => navigate(localizePath(to), options),
  };
}
