import * as React from "react";
import analytics from "./src/core/Analytics";
import GatsbyCommon from "./gatsby-common";

export const wrapPageElement = GatsbyCommon;

export const onRouteUpdate = () => {
  analytics.page();

  console.log(process.env.NODE_ENV === `production`, typeof window?.snaptr === `function`);
  if (process.env.NODE_ENV === `production` && typeof window?.snaptr === `function`) {
    window?.snaptr("track", "PAGE_VIEW");
  } else {
    window.snaptr = () => {};
  }
};
