import * as React from "react";
import styled from "styled-components";

type Props = {
  url: string;
  style?: any;
  children?: any;
};

export default function ImageV2({ url, children, style }: Props) {
  const [u, setU] = React.useState<string>();
  const targetRef = React.useRef<HTMLDivElement>();

  const generateUrl = (q: string) => {
    const path = new URL(url);
    path.searchParams.set("tr", q);
    return decodeURIComponent(path.toString());
  };

  const placeholder = React.useMemo(() => generateUrl("w-32,h-32,bl-3,c-force"), [url]);

  React.useEffect(() => {
    const target = targetRef.current;

    const observer = new IntersectionObserver(
      (entries, observer) => {
        for (const entry of entries) {
          if (target && entry.isIntersecting) {
            const width = Math.round(target.offsetWidth / 64) * 64;
            const yy = generateUrl(`w-${width},dpr-${window?.devicePixelRatio}`);

            setU(yy);

            observer.unobserve(entry.target);
          }
        }
      },
      { rootMargin: "0px 0px 100px 0px" }
    );

    if (target) {
      observer.observe(target);

      return () => observer.unobserve(target);
    }
  }, []);

  return (
    <Wrapper ref={targetRef} url={placeholder} style={style || {}}>
      {u && <Img url={u} />}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ url: string }>`
  position: relative;
  background: #000 url(${({ url }) => url}) center/cover;
`;

const Img = styled.div<{ url: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${({ url }) => url}) center/cover;
`;
