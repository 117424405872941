import * as React from "react";
import styled from "styled-components";

export default function Providers() {
  return (
    <Grid>
      <img src="https://ik.imagekit.io/vxvlabel/assets/providers/klarna.svg" style={{ height: 12 }} />
      <img src="https://ik.imagekit.io/vxvlabel/assets/providers/instabox.svg" style={{ height: 12 }} />
      <img src="https://ik.imagekit.io/vxvlabel/assets/providers/postnord.svg" style={{ height: 12 }} />
    </Grid>
  );
}

const Grid = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;

  img {
    width: auto;
  }
`;
