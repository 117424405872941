import React from "react";
import useLink from "../../hooks/useLink";

type Props = {
  text?: boolean;
  fill?: string;
  width?: number;
  style?: React.CSSProperties;
};

export default function Logo({ text, fill, style, width }: Props) {
  const { navigate } = useLink();

  return (
    <div
      onClick={() => navigate("/")}
      style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer", ...(style || {}) }}
    >
      <svg style={{ width: width || 60 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.62 47.57">
        <path
          fill={fill || "#050605"}
          d="M50.525.868a140.684 140.684 0 0 1-3.7 16.648A102.758 102.758 0 0 1 39.286 36.6c-.225.429-.6.431-.825 0a89.407 89.407 0 0 1-2.875-6.093 2.4 2.4 0 0 1-.009-1.621 120.3 120.3 0 0 0 2.612-6.9A135.427 135.427 0 0 0 43.392.865.719.719 0 0 0 42.657 0h-8.315a1.07 1.07 0 0 0-1.02.867q-1.155 6.95-2.837 13.349c-.124.468-.331.47-.454 0Q28.349 7.823 27.209.866A1.068 1.068 0 0 0 26.186 0h-8.3a.717.717 0 0 0-.733.864 138.4 138.4 0 0 0 5.293 21.442c.754 2.218 1.586 4.4 2.468 6.561a2.4 2.4 0 0 1-.012 1.618 90.055 90.055 0 0 1-2.805 5.936c-.225.43-.6.429-.826 0a102.618 102.618 0 0 1-7.544-19.079A140.008 140.008 0 0 1 10.064.868 1.069 1.069 0 0 0 9.045 0h-8.3a.718.718 0 0 0-.733.866 138.35 138.35 0 0 0 5.262 21.266 120.681 120.681 0 0 0 11.55 24.517 1.75 1.75 0 0 0 1.341.746h7.129a1.731 1.731 0 0 0 1.336-.749 130.941 130.941 0 0 0 3.625-6.416 31.432 31.432 0 0 0 1.576-3c.114-.247.233-.519.355-.8q.6-1.228 1.168-2.468c.2-.441.327-.408.275.074a92.246 92.246 0 0 1-1.368 8.609 2.671 2.671 0 0 0 .254 1.621c.489.856.979 1.711 1.49 2.557a1.736 1.736 0 0 0 1.339.749h7.128a1.736 1.736 0 0 0 1.337-.747 124.48 124.48 0 0 0 11.555-24.67A135.481 135.481 0 0 0 60.6.866.72.72 0 0 0 59.865 0h-8.322a1.067 1.067 0 0 0-1.018.868"
          data-name="Path 30"
        />
      </svg>

      {text && (
        <svg style={{ marginTop: 16 }} xmlns="http://www.w3.org/2000/svg" width="124.423" height="15.451">
          <path
            fill={fill || "#050605"}
            d="m13.992 0-5.5 15.45h-3L-.008 0h3.421l2.914 8.939c.287.861.6 1.986.728 2.538.133-.574.42-1.633.707-2.538L10.609 0Z"
            data-name="Path 31"
          />
          <path
            fill={fill || "#050605"}
            d="M28.692 15.45h-3.686l-1.391-2.34a66.774 66.774 0 0 1-1.677-3.023c-.265.573-1.1 2.053-1.678 3.023l-1.389 2.34h-3.689l4.9-7.9-4.68-7.548h3.69l1.169 1.986c.552.928 1.391 2.45 1.678 3.024.287-.574 1.1-2.052 1.677-3.024l1.17-1.986h3.687l-4.7 7.548Z"
            data-name="Path 32"
          />
          <path
            fill={fill || "#050605"}
            d="m44.092 0-5.5 15.45h-3L30.092 0h3.421l2.914 8.939c.287.861.6 1.986.728 2.538.133-.574.42-1.633.707-2.538L40.709 0Z"
            data-name="Path 33"
          />
          <path fill={fill || "#050605"} d="M64.192 15.45H53.351V0h3.09v12.4h7.751Z" data-name="Path 34" />
          <path
            fill={fill || "#050605"}
            d="M80.715 15.451h-3.4l-1.213-3.443h-5.65l-1.213 3.443h-3.311L71.843.001h3Zm-5.672-6.379-.993-2.8c-.308-.9-.617-1.832-.772-2.406-.154.574-.463 1.5-.773 2.406l-.993 2.8Z"
            data-name="Path 35"
          />
          <path
            fill={fill || "#050605"}
            d="M96.092 10.462c0 3.245-2.184 4.988-5.495 4.988h-7.019V0h7.085c3 0 4.9 1.435 4.9 4.083a3.4 3.4 0 0 1-1.632 3 3.534 3.534 0 0 1 2.162 3.377m-3.664-6.136c0-.971-.552-1.479-1.9-1.479H86.6v2.936h4.083c1.28 0 1.743-.6 1.743-1.457m.486 6.18c0-1.324-.706-1.986-2.207-1.986h-4.1v4h4.1c1.5 0 2.207-.794 2.207-2.008"
            data-name="Path 36"
          />
          <path
            fill={fill || "#050605"}
            d="M110.302 15.45H99.244V0h10.728v3.024h-7.637v2.67h4.437v3h-4.437v3.73h7.968Z"
            data-name="Path 37"
          />
          <path fill={fill || "#050605"} d="M124.423 15.45h-10.837V0h3.09v12.4h7.747Z" data-name="Path 38" />
        </svg>
      )}
    </div>
  );
}
