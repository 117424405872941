import axios from "axios";

// TODO: Add x-api-key in node only
const Brink = axios.create({
  baseURL: "https://api.vxvlabel.brinkcommerce.com",
  headers: {
    "x-api-key": "67GhWvv50glKGeswvnhfrf32",
  },
});

export default Brink;
