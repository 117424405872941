import * as React from "react";
import useCheckout from "../../hooks/useCheckout";
import { Div } from "../../styles/helpers";
import Close from "../misc/Close";
import styled from "styled-components";
import { useToaster } from "../../hooks/useToaster";
import { Loader } from "../common/Button";

export default function CheckoutTotal() {
  const { checkout } = useCheckout();

  return (
    <>
      <PriceRow text="Pris" price={checkout?.totalPrice} />
      <PriceRow text="Rabatt" discount price={checkout?.totalDiscountAmount} />
      <PriceRow text="Total" total price={checkout?.totalPriceWithDiscount} />
    </>
  );
}

type Props = {
  text: string;
  price?: number;
  discount?: boolean;
  total?: boolean;
};

const PriceRow = ({ text, price, discount, total }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const { store, checkout, removeDiscount } = useCheckout();
  const Toaster = useToaster();

  const remove = async () => {
    try {
      setLoading(true);
      await removeDiscount();
    } catch (e) {
      Toaster.show(e?.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {(!discount || price > 0) && (
        <Div font={total ? "v" : ""} margin={[0, 0, 2]} style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{text}</div>

          <div style={{ display: "flex" }}>
            {discount &&
              checkout?.discounts?.rules?.map((discount) => (
                <Tag onClick={remove}>
                  <span>{discount.ruleData.discountCode}</span>
                  {loading ? (
                    <Loader color="black" size={1} style={{ margin: 0 }} />
                  ) : (
                    <Close size={12} onClose={remove} style={{ position: "relative", margin: 0, marginBottom: 2 }} />
                  )}
                </Tag>
              ))}

            <div style={discount ? { color: "red" } : {}}>
              {discount ? "-" : ""}
              {price / 100} {store.currencyUnit}
            </div>
          </div>
        </Div>
      )}
    </>
  );
};

const Tag = styled.div`
  background: #eee;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`;
