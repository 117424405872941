import * as React from "react";
import Menu from "./src/components/layout/Menu";
import Footer from "./src/components/layout/Footer";
import Cookies from "./src/components/misc/Cookies";
import { RecoilRoot } from "recoil";
import { storeState } from "./src/hooks/useCheckout";
import ToasterProvider from "./src/hooks/useToaster";
import GlobalStyle from "./src/styles/global";
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";

const GatsbyCommon = ({ element, props }: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) => {
  const initializeState = ({ set }) => set(storeState, props.pageContext.store);

  return (
    <RecoilRoot initializeState={initializeState} {...props}>
      <GlobalStyle />
      <ToasterProvider>
        <Menu isIndex={/^\/[a-z]{2}\/?$/.test(props.location?.pathname)} />
        <div style={{ paddingTop: 60, flex: 1 }}>{element}</div>
        <Footer />
        <Cookies />
      </ToasterProvider>
    </RecoilRoot>
  );
};

export default GatsbyCommon;
