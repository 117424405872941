import * as React from "react";
import Toaster from "../components/misc/Toaster";

type Props = {
  value?: any | string | null;
};

const ToasterContext = React.createContext<{ setValue: (value: Props["value"]) => void }>({ setValue: () => {} });

const ToasterProvider = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = React.useState<Props["value"]>(null);

  React.useEffect(() => {
    if (value) {
      const timer = setTimeout(() => setValue(null), 4000);
      return () => clearTimeout(timer);
    }
  }, [value, setValue]);

  return (
    <ToasterContext.Provider value={{ setValue }}>
      {children}
      {value && <>{typeof value !== "string" ? value : <Toaster>{value}</Toaster>}</>}
    </ToasterContext.Provider>
  );
};

export default ToasterProvider;

export const useToaster = () => {
  const { setValue } = React.useContext(ToasterContext);

  return {
    show: (children: Props["value"]) => setValue(children),
    hide: () => setValue(null),
  };
};
