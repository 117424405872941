import Analytics from "analytics";
// @ts-ignore
import mixpanelPlugin from "@analytics/mixpanel";

const DEV = process.env.NODE_ENV === "development";

const analytics = Analytics({
  app: "vxvlabel",
  plugins: DEV
    ? []
    : [
        mixpanelPlugin({
          token: "6c9d461332ba829de7e8325bc9501546",
          debug: false,
          pageEvent: "Route",
          options: {
            debug: false,
            ignore_dnt: true,
          },
        }),
      ],
  debug: false,
});

export default analytics;
