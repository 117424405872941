import * as React from "react";
import styled from "styled-components";

export default function Close({ onClose, size, style }: { onClose: () => void; size?: number; style?: any }) {
  return (
    <Container onClick={onClose} size={size} style={style || {}}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
      </svg>
    </Container>
  );
}

const Container = styled.div<{ size?: number }>`
  cursor: pointer;
  width: ${({ size }) => size || 20}px;
  height: ${({ size }) => size || 20}px;
  margin: ${({ size }) => size || 20}px;
  position: absolute;
  top: 0;
  right: 0;
`;
