import * as React from "react";
import Button from "../common/Button";
import useCheckout from "../../hooks/useCheckout";
import Drawer from "../layout/Drawer";
import CartRow from "./CartRow";
import useLink from "../../hooks/useLink";
import { Div } from "../../styles/helpers";
import CheckoutTotal from "./CheckoutTotal";
import Providers from "../promotion/Providers";

export default function CartDrawer(props) {
  const { navigate } = useLink();
  const { store, cart, checkout } = useCheckout();

  const untilFreeShipping = 300 - ((checkout?.totalPriceWithDiscount || 0) / 100 || 0);

  return (
    <>
      <Drawer {...props}>
        <Div font="v" margin={[0, 0, 30]}>
          Your cart
        </Div>

        <div style={{ flex: 1, overflow: "auto" }}>
          {cart.map((product) => (
            <CartRow key={product.id} product={product} />
          ))}
        </div>

        {cart?.[0] && (
          <div style={{ marginBottom: 10 }}>
            <CheckoutTotal />
          </div>
        )}

        <Button name="checkout" onClick={() => navigate("/checkout")} disabled={cart.length === 0}>
          Till kassan
        </Button>

        <Providers />
        <div style={{ marginTop: 20, textAlign: "center", fontWeight: "500" }}>
          {untilFreeShipping > 0 ? (
            <>
              {untilFreeShipping} {store.currencyUnit} kvar till fri frakt
            </>
          ) : (
            <>Du har gratis frakt!</>
          )}
        </div>
      </Drawer>
    </>
  );
}
