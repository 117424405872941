import * as React from "react";
import styled, { css } from "styled-components";
import { COLORS, Div } from "../../styles/helpers";
import analytics from "../../core/Analytics";

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  disabled?: boolean;
  children?: any;
  secondary?: boolean;
  name: string;
  small?: boolean;
  text?: boolean;
};

const Button = ({ name, children, loading, ...props }: Props) => {
  const ref = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (name) {
      const fn = () => analytics.track("Button", { name, route: location.href });

      ref.current?.addEventListener("click", fn);

      return () => ref.current?.removeEventListener("click", fn);
    }
  }, [name]);

  return (
    <Wrapper ref={ref} disabled={props?.disabled || loading} {...props}>
      {loading && <Loader />}
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled(Div)<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 100%;
  text-transform: uppercase;
  padding-inline: 36px;

  ${({ secondary }) => css`
    background: ${secondary ? COLORS.beige : COLORS.dark};
    color: ${secondary ? COLORS.dark : "white"};
  `}

  ${({ small }) => css`
    font-size: ${small ? 11 : 12}px;
    letter-spacing: ${small ? 2 : 4}px;
    height: ${small ? 36 : 48}px;
    border-radius: ${small ? 4 : 6}px;
  `}

  &[disabled] {
    color: rgba(255, 255, 255, 0.4);
  }

  ${({ text }) => text && `all: unset;`}

  cursor: pointer;
  border: 0;
  outline: none;
`;

export const Loader = styled.div<{ color?: string; size?: number }>`
  border: ${({ size }) => size || 3}px solid transparent;
  border-top: ${({ size }) => size || 3}px solid ${({ color }) => color || "#FFF"};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  margin-right: 6px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
