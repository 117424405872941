import { createGlobalStyle } from "styled-components";
import { COLORS } from "./helpers";

const GlobalStyle = createGlobalStyle`
    *:where(:not(head, html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
        all: unset;
        display: revert;
    }

    * {
        box-sizing: border-box;
    }

    div, img {
        display: block;
    }

    img {
        width: 100%;
    }

    body {
        font-size: 13px;
        font-family: "Jost", sans-serif;
        font-weight: 300;
        background: ${COLORS.gray};
    }

    a {
        cursor: pointer;
    }

    #gatsby-focus-wrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
`;

export default GlobalStyle;
