import * as React from "react";
import styled, { css } from "styled-components";
import Close from "../misc/Close";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  left?: boolean;
};

export default function Drawer({ children, onClose, ...props }: Props) {
  return (
    <Overlay onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()} {...props}>
        <Close onClose={onClose} />
        {children}
      </Wrapper>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: flex-end;
  z-index: 5;
`;

const Wrapper = styled.div<{ left?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ left }) =>
    left
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
  z-index: 10;
  background: #fff;
  position: fixed;
  padding: 20px;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 300px;
`;
