import * as React from "react";
import Button from "../common/Button";
import styled, { keyframes } from "styled-components";
import { Div } from "../../styles/helpers";
import useCheckout from "../../hooks/useCheckout";
import CartRow from "./CartRow";
import useLink from "../../hooks/useLink";
import Close from "../misc/Close";
import { useToaster } from "../../hooks/useToaster";

export default function ProductAddedPopup() {
  const Toaster = useToaster();
  const { navigate } = useLink();
  const { store, checkout, cart } = useCheckout();

  return (
    <Wrapper>
      <Close onClose={() => Toaster.hide()} style={{ margin: 10 }} />

      <Div font="v" margin={[0, 0, 10]}>
        Varukorg
      </Div>
      <div style={{ overflow: "auto" }}>
        {cart.map((product) => (
          <CartRow key={product.id} product={product} />
        ))}
      </div>
      <Button onClick={() => navigate("/checkout")} name="added-popup" small style={{ marginTop: 10 }}>
        Till kassan {checkout?.totalPriceWithDiscount / 100} {store.currencyUnit}
      </Button>
    </Wrapper>
  );
}

const rotate = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  background: white;
  position: fixed;
  top: 66px;
  right: 6px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 260px;
  padding: 10px;
  max-height: calc(100vh - 60px - 18px);
  display: flex;
  flex-direction: column;
  z-index: 3;
  animation: ${rotate} 0.6s ease-in-out forwards;
`;
