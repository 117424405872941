import * as React from "react";
import { atom, useRecoilState } from "recoil";
import Brink from "../core/Brink";
import { BrinkStore, BrinkCart } from "../types/brink";
import { useToaster } from "./useToaster";
import analytics from "../core/Analytics";
import ProductAddedPopup from "../components/cart/ProductAddedPopup";

export const key = "vxv_checkout";

export const storeState = atom<BrinkStore>({
  key: "store",
  default: {
    countryCode: "se",
    languageCode: "sv",
    currencyUnit: "SEK",
    taxPercentage: 25,
  },
});

const checkoutState = atom<BrinkCart | null>({
  key: "cart",
  default: null,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === "get") {
        const jwtToken = typeof window !== "undefined" ? localStorage?.getItem(key) : null;

        if (jwtToken) {
          (async () => {
            const { data } = await Brink.get(`/orderv1/carts`, { headers: { authorization: jwtToken } });

            if (data.state !== "CLOSED") {
              setSelf(data);
            } else {
              localStorage?.removeItem(key);
            }
          })();
        }
      }
    },
  ],
});

export default function useCheckout() {
  const [checkout, setCheckout] = useRecoilState(checkoutState);
  const [store, setStore] = useRecoilState(storeState);
  const Toaster = useToaster();

  return {
    store,

    checkout,

    cart: checkout?.cartItems || [],

    async addToCart(productId: string, quantity = 1) {
      try {
        const products = [{ id: productId, quantity }];

        const { data } = localStorage?.getItem(key)
          ? await Brink.put("/orderv1/carts", { products }, { headers: { authorization: localStorage?.getItem(key) } })
          : await Brink.post("/orderv1/carts", { products, store });

        setCheckout(data.cart);

        localStorage?.setItem(key, data.jwtToken);

        if (quantity > 0) {
          Toaster.show(<ProductAddedPopup />);
        }

        analytics.track("Add To Cart", { productId, quantity });
        window?.snaptr("track", "ADD_CART");
      } catch (e: any) {
        Toaster.show(e?.response?.data?.message || e.message);
      }
    },

    async addDiscount(code: string) {
      if (checkout && code) {
        const { data } = await Brink.put(
          "/orderv1/carts",
          { code },
          { headers: { authorization: localStorage?.getItem(key) } }
        );

        setCheckout(data.cart);

        analytics.track("Add Discount", { code });
      }
    },

    async removeDiscount() {
      if (checkout) {
        const { data } = await Brink.put(
          "/orderv1/carts",
          { deleteDiscount: true },
          { headers: { authorization: localStorage?.getItem(key) } }
        );

        setCheckout(data.cart);

        analytics.track("Remove Discount");
      }
    },
  };
}
