import * as React from "react";
import useCheckout from "../../hooks/useCheckout";
import Close from "../misc/Close";
import styled from "styled-components";
import { Loader } from "../common/Button";
import useLink from "../../hooks/useLink";
import ImageV2 from "../common/ImageV2";

type Props = {
  product: any;
};

export default function CartRow({ product }: Props) {
  const { navigate } = useLink();
  const { store, addToCart } = useCheckout();
  const [loading, setLoading] = React.useState(false);

  const remove = async () => {
    try {
      setLoading(true);
      await addToCart(product.id, 0 - product.quantity);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", marginBottom: 4, position: "relative" }}>
      {loading ? (
        <Loader color="black" size={1} style={{ margin: 2, position: "absolute", top: 0, right: 0 }} />
      ) : (
        <Close size={12} onClose={remove} style={{ margin: 2 }} />
      )}

      <div onClick={() => navigate(`/${product.relatedProduct}`)}>
        <ImageV2 url={product.imageUrl} style={{ width: 64, aspectRatio: 2 / 3 }} />
      </div>

      <div style={{ paddingInline: 5 }}>
        <div
          onClick={() => navigate(`/${product.relatedProduct}`)}
          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: 150 }}
        >
          {product.name}
        </div>
        {product.price[store.currencyUnit] / 100} {store.currencyUnit}
        <br />
        Storlek: {product.id.match(/(?:-|^)([A-Z])(?:-|$)/)?.[1]}
        <br />
        <QuantitySelector {...product} />
      </div>
    </div>
  );
}

const QuantitySelector = ({ id, quantity }: Props["product"]) => {
  const [loading, setLoading] = React.useState(false);
  const { addToCart } = useCheckout();

  const update = async (q: number) => {
    try {
      setLoading(true);
      await addToCart(id, q);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Box onClick={() => update(-1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
          <path d="M0 11h24v2H0z" />
        </svg>
      </Box>
      <Box>{loading ? <Loader color="black" size={1} style={{ margin: 0 }} /> : quantity}</Box>
      <Box onClick={() => update(1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24">
          <path d="M0 11h24v2H0z" />
          <path d="M11 0v24h2V0z" />
        </svg>
      </Box>
    </div>
  );
};

const Box = styled.div`
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
`;
