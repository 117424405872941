import styled, { css } from "styled-components";

export const COLORS = {
  gray: "#DAD9D6",
  beige: "#ECEAE4",
  dark: "#151414",
};

export const DESKTOP = 1024;

export const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
  margin: 0 auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1px 0;
  grid-gap: 10px;

  @media (max-width: ${DESKTOP}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ScrollGrid = styled.div<{ n: number }>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(${({ n }) => n}, calc(24% - 20px));
  overflow: scroll;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(${({ n }) => n}, calc(50% - 20px));
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
`;

type Props = {
  padding?: (string | number)[];
  margin?: (string | number)[];
  font?: keyof Text;
};

const Text = {
  large: css`
    font-size: 36px;
    font-weight: 800;
  `,
  v: css`
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
  `,
  small: css`
    font-size: 12px;
  `,
};

export const Div = styled.div<Props>`
  ${(props) => Text[props.font] || ``};
  margin: ${({ margin }) => margin?.map((_) => `${_}px `) || 0};
  padding: ${({ padding }) => padding?.map((_) => `${_}px `) || 0};
`;

export const StaticWrapper = styled(Wrapper)`
  padding: 20px;
  box-sizing: border-box;
  background: white;
  font-size: 14px;
  margin: 20px auto;
  max-width: 1200px;
`;
