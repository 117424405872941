import * as React from "react";
import styled from "styled-components";
import { useToaster } from "../../hooks/useToaster";
import Close from "./Close";

export default function Toaster({ children }: { children: React.ReactNode }) {
  const Toaster = useToaster();

  return (
    <Wrapper>
      {children}

      <Close onClose={() => Toaster.hide()} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #000;
  padding: 20px;
  background: hsl(0, 85%, 83%);
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border-radius: 2px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;
