import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import useLink from "../../hooks/useLink";
import { COLORS } from "../../styles/helpers";
import Newsletter from "../promotion/Newsletter";
import Usp from "../promotion/Usp";
import Logo from "../promotion/Logo";

export default function Footer() {
  const { navigate } = useLink();

  return (
    <Wrapper>
      {/* <Newsletter /> */}

      <Logo text fill="#FFF" style={{ marginBlock: 40 }} />

      <Links>
        {/* <a onClick={() => navigate("/shipping")}>FAQ</a> */}
        <a onClick={() => navigate("/contact")}>Kontakta oss</a>
        <a onClick={() => navigate("/shipping")}>Leverans &amp; Retur</a>
        <a onClick={() => navigate("/sizeguide")}>Storleksguide</a>
        <a onClick={() => navigate("/toc")}>Terms & Conditions</a>
        <a onClick={() => navigate("/privacy")}>Privacy Policy</a>
      </Links>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${COLORS.dark};
  color: #fff;
  padding: 20px;
  display: grid;
  place-items: center;
`;

const Links = styled.div`
  padding: 20px 0;
  text-align: center;

  a {
    display: block;
    padding: 0 0 10px;
  }
`;
