exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-success-index-tsx": () => import("./../../../src/pages/checkout/success/index.tsx" /* webpackChunkName: "component---src-pages-checkout-success-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-pages-sizeguide-tsx": () => import("./../../../src/pages/sizeguide.tsx" /* webpackChunkName: "component---src-pages-sizeguide-tsx" */),
  "component---src-pages-toc-tsx": () => import("./../../../src/pages/toc.tsx" /* webpackChunkName: "component---src-pages-toc-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

